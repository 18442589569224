.gradient {
  --magic-rainbow-color-1: hsl(0deg, 96%, 55%);
  --magic-rainbow-color-2: hsl(25deg, 100%, 50%);
  --magic-rainbow-color-1: hsl(40deg, 100%, 50%);
  background: linear-gradient(
    170deg,
    var(--magic-rainbow-color-1),
    var(--magic-rainbow-color-2)
  );
  /* 🤯 */
  transition: --magic-rainbow-color-1 1000ms linear,
    --magic-rainbow-color-2 1000ms linear, --magic-rainbow-color-3 1000ms linear;
}

.overlay-bg {
  /* //min-height: 80vh; */
  opacity: 0.8;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin: 0;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  filter: blur(0px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  -moz-filter: blur(20px);
  -webkit-filter: blur(0px);
}

.blurred-bg {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(15px);
  /* backdrop-filter: blur(2px); */
}

section {
  height: 90vh;
  line-height: 1.5;
}
section .layout {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* bottom: 50%; */
  /* right: 50%; */
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 100px;
  /* box-shadow: 0 10px 20px rgb(0 0 0); */
  color: rgb(255, 254, 254);
}
section .layout::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-attachment: fixed;
  background: rgba(34, 34, 34, 0.8);
  filter: blur(50px);
}
section .layout h2 {
  /* font-family: "Montserrat", sans-serif; */
  /* //font-family: "Titillium Web", sans-serif; */
  font-family: "Shadows Into Light", cursive;
  font-weight: 900;
  font-size: 5rem;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  background: #d4d2d2;
  background: -webkit-linear-gradient(to right, #d4d2d2 0%, #cfcfcf 100%);
  background: -moz-linear-gradient(to right, #d4d2d2 0%, #cfcfcf 100%);
  background: linear-gradient(to left, #d4d2d2 0%, #cfcfcf 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  /* max-width: 1400px; */
  margin-top: 20px;
}
.row {
  align-items: center;
}
.image-2 {
  width: 60%;
  max-width: 100%;
}

.col-sm-12 h3 {
  font-size: 4rem;
  line-height: 1.2;
  text-align: left;
  background: hsla(334, 70%, 80%, 1);
  background-color: hsla(334, 70%, 80%, 1);
  background: conic-gradient(
    hsla(334, 70%, 80%, 1) 16.666%,
    hsla(347, 70%, 80%, 1) 16.666%,
    hsla(347, 70%, 80%, 1) 33.333%,
    hsla(129, 70%, 80%, 1) 33.333%,
    hsla(129, 70%, 80%, 1) 50%,
    hsla(85, 70%, 80%, 1) 50%,
    hsla(85, 70%, 80%, 1) 66.666%,
    hsla(183, 70%, 80%, 1) 66.666%,
    hsla(183, 70%, 80%, 1) 83.333%,
    hsla(308, 70%, 80%, 1) 83.333%
  );

  /* Set thee background size and repeat properties. */
  background-size: 57%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5rem;
  line-height: 1.2;
  text-align: left;
  /* //margin-top: 200px; */
}
.mb-200 {
  margin-bottom: 200px;
}
b {
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: left;
  font-weight: 200;
  margin-top: 40px;
  text-transform: none;
}
.flixmedia {
  background: #41e886;
  background: -webkit-linear-gradient(to right, #41e886 0%, #13ebb4 100%);
  background: -moz-linear-gradient(to right, #41e886 0%, #13ebb4 100%);
  background: linear-gradient(to left, #41e886 0%, #13ebb4 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right top,
    #41e886,
    #31e996,
    #20eaa6,
    #13ebb4,
    #12ebc1
  );
}
a {
    word-break: break-word;
}
.examples a{
    color: #fff;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 6px 15px;
    font-size: 1.8rem;
    display: block;
    margin: 5px 10px;
    float: left;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    border-radius: 0.5rem;
    box-shadow: 0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(0, 0, 0, 0.1);
    transition: transform var(--transition-duration) var(--transition-easing);
    overflow: hidden;
}
.examples a:hover {
  color: #0056b3;
  /* text-decoration: underline; */
  background: hsla(334, 70%, 80%, 1);
  background-color: hsla(334, 70%, 80%, 1);
  background: conic-gradient( hsla(334, 70%, 80%, 1) 16.666%, hsla(347, 70%, 80%, 1) 16.666%, hsla(347, 70%, 80%, 1) 33.333%, hsla(129, 70%, 80%, 1) 33.333%, hsla(129, 70%, 80%, 1) 50%, hsla(85, 70%, 80%, 1) 50%, hsla(85, 70%, 80%, 1) 66.666%, hsla(183, 70%, 80%, 1) 66.666%, hsla(183, 70%, 80%, 1) 83.333%, hsla(308, 70%, 80%, 1) 83.333% );
  background-size: 57%;
  background-repeat: repeat;
  /* -webkit-text-fill-color: transparent; */
  transition: --magic-rainbow-color-1 1000ms linear, --magic-rainbow-color-2 1000ms linear, --magic-rainbow-color-3 1000ms linear;
}
.examples a::before {
  content: "";
  background: rgba(255, 255, 255, 0.4);
  width: 60%;
  height: 100%;
  top: 0%;
  left: -125%;
  transform: skew(45deg);
  position: absolute;
  transition: left var(--transition-duration) var(--transition-easing);
}
.examples a:hover {
  -webkit-transform: translate(-4%, -4%);
  transform: translate(-4%, -4%);
}
.examples a:hover::before {
  left: 150%;
}
.examples{margin:10% 0;}
.examples h3{font-size: 3rem;text-align:left}
@media only screen and (max-width: 900px) {
  section .layout h2 {
    font-size: 4rem;
    line-height: 1.2;
  }
  .col-sm-12 h3 {
    font-size: 3rem;
    line-height: 1.2;
  }
  .col-sm-12 p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media only screen and (max-width: 768px) {
  section .layout h2 {
    font-size: 3rem;
    line-height: 1.2;
  }
  .col-sm-12 p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .col-sm-12 h3 {
    font-size: 3rem;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 500px) {
  section .layout h2 {
    font-size: 2rem;
    line-height: 1.2;
  }
  .col-sm-12 h3 {
    font-size: 2rem;
    line-height: 1.2;
  }
  .col-sm-12 p {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 20px;
  }
}
